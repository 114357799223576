<template>
	<TheHeader>
		<template v-slot:title>
			The Most Founder-Friendly and Tax-Efficient Employee Participation Scheme. <span class="line relative inline-block text-theme">Automated.</span>
		</template>
		<template v-slot:description>
			Attract and incentivize talent. Lay the foundation of a committed workforce.
		</template>
	</TheHeader>

	<!--Light-->
	<div>
		<section class="page-container md:flex md:items-center md:space-x-12 py-20 md:space-y-0 space-y-16">
			<div data-aos="fade-up" class="xl:w-5/12 md:w-6/12">
				<h3 class="tracking-tight 2xl:text-4xl text-3xl font-black leading-snug mb-4">
					Zero Financial Implications for a Company
				</h3>
				<p class="text-secondary leading-7 font-semibold">
					Employee participation with Optiomat is based on creating an option pool and issuing options over a part of founder's share. Options are not company’s liabilities and therefore don’t require auditing or accounting. Moreover, such ESOPs shouldn't be considered in the shareholders agreement.
				</p>
			</div>
			<kinesis-container event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12">
				<kinesis-element
					tag="img"
					src="/images/zero-financial-implications.svg"
					:strength="60"
					type="translate"
					class="xl:max-w-2xl md:max-w-md w-full inline-block"
				/>
			</kinesis-container>
		</section>
		<figure class="bg-wave-secondary bg-w-full bg-no-repeat bg-bottom h-12 w-full block transform lg:translate-y-1 translate-y-0.5"></figure>
	</div>

	<!--Secondary-->
	<div class="bg-secondary">
		<section class="page-container md:flex md:items-center md:space-x-12 py-20 md:space-y-0 space-y-16">
			<kinesis-container event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12">
				<kinesis-element
					tag="img"
					src="/images/easy-implementation.svg"
					:strength="60"
					type="translate"
					class="inline-block md:max-w-md md:w-full sm:w-96 w-72"
				/>
			</kinesis-container>
			<div data-aos="fade-up" class="xl:w-5/12 md:w-6/12">
				<h3 class="tracking-tight 2xl:text-4xl text-3xl font-black leading-snug mb-4">
					Easy Implementation for a Company Founder
				</h3>
				<p class="text-secondary leading-7 font-semibold">
					Normally, it would take weeks of time to setting up an effective and compliant ESOP scheme. With Optiomat, you can automate and streamline the complexity of the ESOP processes without a huge learning curve, so that setting up an ESOP scheme for your key hires is a matter of a few clicks.
				</p>
			</div>
		</section>
		<figure class="bg-wave-white bg-w-full bg-no-repeat bg-bottom h-12 w-full block transform lg:translate-y-1 translate-y-0.5"></figure>
	</div>

	<!--Light-->
	<div>
		<section class="page-container md:flex md:items-center md:space-x-12 py-20 md:space-y-0 space-y-16">
			<div data-aos="fade-up" class="xl:w-5/12 md:w-6/12">
				<h3 class="tracking-tight 2xl:text-4xl text-3xl font-black leading-snug mb-4">
					Friendly Taxation for Employees
				</h3>
				<p class="mb-6 text-secondary leading-7 font-semibold">
					Giving away shares would lead to an immediate “dry” tax claim for your employees. That is why the Optiomat model of employee participation is based on options. Generally, no taxes are due at the time of an option grant.
				</p>
				<p class="text-secondary leading-7 font-semibold">
					By a suitable setting of the option exercise period, the moment of taxation can be moved to infinity until the moment when the employee receives something of immediate value, like dividends or exit proceedings.
				</p>
			</div>
			<kinesis-container event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12">
				<kinesis-element
					tag="img"
					src="/images/friendly-taxation.svg"
					:strength="60"
					type="translate"
					class="inline-block md:max-w-md md:w-full sm:w-96 w-72"
				/>
			</kinesis-container>
		</section>
		<figure class="bg-wave-secondary bg-w-full bg-no-repeat bg-bottom h-12 w-full block transform lg:translate-y-1 translate-y-0.5"></figure>
	</div>

	<!--Secondary-->
	<div class="bg-secondary">
		<section class="page-container md:flex md:items-center md:space-x-12 py-20 md:space-y-0 space-y-16">
			<kinesis-container event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12">
				<kinesis-element
					tag="img"
					src="/images/cost-and-time-saving.svg"
					:strength="60"
					type="translate"
					class="xl:max-w-2xl md:max-w-md w-full inline-block"
				/>
			</kinesis-container>
			<div data-aos="fade-up" class="xl:w-5/12 md:w-6/12">
				<h3 class="tracking-tight 2xl:text-4xl text-3xl font-black leading-snug mb-4">
					Cost and Time Savings for All Involved
				</h3>
				<p class="text-secondary leading-7 font-semibold">
					Every company founder wants to attract and retain a thriving talent. But not every founder is in a position to spend weeks and pay thousands to set up a valuable ESOP scheme.
				</p>
				<p class="mb-6 text-secondary leading-7 font-semibold">
					Optiomat removes all that pain and offers its services for a fraction of costs you would expect. And it is always completely free for your employees.
				</p>
			</div>
		</section>
		<figure class="bg-wave-white bg-w-full bg-no-repeat bg-bottom h-12 w-full block transform lg:translate-y-1 translate-y-0.5"></figure>
	</div>

	<!--Light-->
	<div>
		<section class="page-container md:flex md:items-center md:space-x-12 py-20 md:space-y-0 space-y-16">
			<div data-aos="fade-up" class="xl:w-5/12 md:w-6/12">
				<h3 class="tracking-tight 2xl:text-4xl text-3xl font-black leading-snug mb-4">
					Specifically Designed for Limited Liability Companies...
				</h3>
				<b class="text-theme mb-4 block">Such as BV, GmbH, SARL, SRL, S.L., s.r.o., Sp. z o.o., Oy, OÜ, KÜB, KB, KS, Lda., d.o.o., IVS, ApS, kft., OOO</b>
				<p class="mb-6 text-secondary leading-7 font-semibold">
					There are many formal requirements for the transfer of shares in a limited liability company, the most frequent legal form of startup corporate entity. To avoid these restrictions, Optiomat allows employees participate via a trust arrangement in which a specifically designed trustee holds option pool shares in your company.
				</p>
				<p class="text-secondary leading-7 font-semibold">
					Employees are than entitled to acquire trustee's depositary receipts instead of company shares. In this way, the whole ESOP scheme can be digitized and automated in a way that would never have been possible with direct stakes in a limited liability company.
				</p>
			</div>
			<kinesis-container event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12">
				<kinesis-element
					tag="img"
					src="/images/designed-for-companies.svg"
					:strength="60"
					type="translate"
					class="inline-block md:max-w-md md:w-full sm:w-96 w-72"
				/>
			</kinesis-container>
		</section>
		<figure class="bg-wave-dark bg-w-full bg-no-repeat bg-bottom h-12 w-full block transform lg:translate-y-1 translate-y-0.5"></figure>
	</div>

	<!--Dark-->
    <div class="bg-primary -mb-32">
        <section class="page-container md:flex md:items-center md:space-x-12 pt-20 pb-28 md:space-y-0 space-y-16">
			<kinesis-container event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12">
				<kinesis-element
					tag="img"
					src="/images/dutch-model.svg"
					:strength="60"
					type="translate"
					class="inline-block md:max-w-md md:w-full sm:w-96 w-72"
				/>
			</kinesis-container>
            <div data-aos="fade-up" class="xl:w-5/12 md:w-6/12">
                <h3 class="tracking-tight 2xl:text-4xl text-3xl font-black leading-snug text-white mb-4">
                    Proven Dutch Model with International Applicability
                </h3>
                <p class="mb-6 text-secondary leading-7 text-dark font-semibold">
                    It doesn’t matter where you are from, your Optiomat ESOPs will be governed by Dutch law, which means that all parties can rely on a stable legislative environment, proven legal procedures and the smooth enforceability of the law, transferable to a judicial decision of other European countries.
				</p>
                <p class="text-secondary leading-7 text-dark font-semibold">
                    ESOPs created and managed through Optiomat mean a wide international application regardless of the domicile of the company's founder, the company itself and its employees.
				</p>
            </div>
        </section>
    </div>
</template>
<script>
	import TheHeader from "../components/TheHeader";

	export default {
		name: 'WhyOptiomat',
		components: {
			TheHeader,
		},
	}
</script>